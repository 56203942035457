<template>
  <div class="wrap">
    <viewer :images="images">
      <el-row :gutter="20">
        <el-col :span="6" v-for="item in images" :key="item.url">
          <div class="img-wrap">
            <img :src="item.url" :key="item.url">
            <div class="name"><i :class="item.icon"></i> {{item.name}}</div>
          </div>
        </el-col>
      </el-row>
    </viewer>
    <div class="word">
      <p>地址：北京朝阳区北土城西路19号</p>
      <p>邮编：100029</p>
      <p>电话：010-82998418</p>
      <p>传真：010-62010846</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        {name: '地理位置', icon: 'el-icon-location', url: 'http://www.igg.cas.cn/lxwm/200908/W020141210351787781681.jpg'},
        {name: '周边区域图', icon: 'el-icon-s-flag', url: 'http://www.igg.cas.cn/lxwm/200908/W020141210351787794700.jpg'},
        {name: '交通示意图', icon: 'el-icon-truck', url: 'http://www.igg.cas.cn/lxwm/200908/W020141210351787827281.jpg'},
        {name: '园区示意图', icon: 'el-icon-guide', url: 'http://www.igg.cas.cn/lxwm/200908/W020141202560314433924.jpg'}
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.wrap {
  padding: 20px;
}
.img-wrap {
  position: relative;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 210px;
  border: 1px solid #eee;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06);
  background-color: #f4f5fa;
  overflow: hidden;
  img {
    position: relative;
    width: 100%;
    cursor: pointer;
    transform: scale(1.7);
    transition: all .2s ease;
    &:hover {
      transform: scale(1.9);
    }
  }
  .name {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 12px;
    width: 100%;
    background-color: rgba(64, 158, 255, 0.9);
    line-height: 40px;
    color: #fff;
  }

}
.word {
  margin: 20px 0;
  line-height: 30px;
}
</style>
